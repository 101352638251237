:root {
    --dark-highlight: #443e94;
    --primary: #443e94;
}

.text-center {
    text-align: center !important;
    margin: 0 auto;
}

#map {
    margin-bottom: 30px;
    border-radius: 20px;
}

p {
    line-height: 2;
    color: gray;
    font-weight: 400;
}

#app-tab-bar {
    --background: white !important;
    border-top: 1px solid lightgray;
}

.ios-wrapper-margin {
    margin-top: 5vh !important;
}

.ios-wrapper{
    padding-top: 5vh !important;
}

.search-bar {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
}

.search-bar-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    margin: 0;
    margin-top: -10px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
    background-color: whitesmoke;
    --padding-top: 50px !important; /* Adjust the padding to make space for the revealed search bar */
    transition: 0.3s !important; /* Add smooth transition when adjusting padding */
}

/* Conrirmation*/

@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);

.highlight {
    color: var(--secondary) !important;
}

ion-button {
    --background-activated: unset;
}

.top-bar {
    margin-bottom: 60px;
}

.scrolled {
    background-color: white;
}

.ios-wrap .fixed-top {
    padding-top: 60px;
}
.fixed-top {
    transition: .3s;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.favourite-icon {
    text-align: right !important;
}

.back-arrow {
    font-size: 28px;
}

.action-button {
    margin-top: 10px;
    width: 100%;
    --border-radius: 100px;
    --padding-top: 30px;
    font-size: 16px;
    --padding-bottom: 30px;
    --background: whitesmoke;
    --color: gray;
    --box-shadow: none;
    --border: none;
}

.dark-highlight-button {
    --background: #eaeaea !important;
    box-shadow: none;;
}

.highlight-button {
    --background: var(--dark-highlight);
    color: white;
}

ion-grid {
    padding: 0px;
}

ion-row {
    padding: 0px;
}

ion-row.no-padding ion-col {
    padding: 0px;
}

.align-right {
    text-align: right !important;
}

ion-tab-bar {
    border-top: 1px solid var(--dark-highlight);
    --background: var(--darker-highlight) !important;
}

ion-tab-bar ion-icon {
    color: #38404D;
    font-size: 30px;
}

.no-padding {
    margin: 0px !important;
    padding: 0px !important;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
}

.align-middle {
    vertical-align: middle !important;
}

.lowercase {
    text-transform: unset !important;;
}

.authentication-button {
    font-size: 14px !important;
    line-height: 1.5;
    --padding-bottom: 3px !important;
}

.wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

.page-header {
    padding-top: 0px;
}

.page-header h1 {
    color: white;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 900;
    letter-spacing: -3px;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1;
}

.page-header p {
    line-height: 1.5;
    font-size: 18px;
    font-weight: 500;
}

.icon-img {
    width:16px !important;
    margin-top: unset !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.note {
    padding: 10px 20px;
    border-left: 2px solid var(--primary);
    background-color: whitesmoke;
}

.note p {
    padding: 0;
    margin: 0;
}

.note p a {
    color: var(--primary);
    text-decoration: none;
}

input[type="radio"] {
    margin-top: 22px;
}

/* Trending Events */

.trending-events {
    margin-top: 0px;
}

.trending-events .title h2 {
    font-size: 20px;
    vertical-align: bottom;
}

.view-all-button {
    color: var(--secondary) !important;
    font-size: 16px;
    font-weight: 500;
    vertical-align: top !important;
    line-height: 2.3;
}

.event {
    margin-bottom: 30px;
    background-color: #252A33;
    border-radius: 16px;
}

.event-image {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    height: 300px;
    position: relative;
}

.event-date {
    margin-bottom: 10px;
    margin-left: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--secondary);
    width: 55px;
    border-radius: 16px;
    height: 55px;
}

.event-date h2 {
    text-align: center;
    color: var(--ion-background-color);
    font-weight: 900;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    line-height: 0;
    margin-top: 18px;
}
.event-date h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-top: 14px;
}

.event-details {
    padding: 20px;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

.event-details h2 {
    font-weight: 500;
    font-size: 20px;
}

.event-details .highlight {
    font-size: 15px;
    padding-left: 2px;
    vertical-align: middle;
    font-weight: 400;
}

.event-details h3 {
    font-weight: 400;
    font-size: 15px;
    margin-top: 0px;
}

.event-details hr {
    border-bottom: 1px solid #38404D;
    border-color: #38404D;
    padding-top: 10px;
}

.event-details h4 {
    font-weight: 500;
    font-size: 16px;
}
.event-details .ticket-text {
    font-size: 14px;
    margin-left: 2px;
    font-weight: 400;
}

.event-details h5 {
    font-size: 14px !important;
    line-height: 1.5;
}

.event-details .view-details-button {
    --background: transparent;
    align-content: right !important;
}

/* View Event */

.view-event {
    position: relative;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.view-event .event-image-background {
    height: 50vh;
    z-index: 0;
}

.view-event-details {
    /* margin-top: -70px; */
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0 !important;
    padding-bottom: 100px;
}

.view-event-details .verified {
    margin-bottom: -20px;
    font-size: 20px;
    font-weight: 400;
}

.view-event-details  .view-event-artist {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 900;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -2px;
}

.view-event-details .view-event-description {
    font-size: 16px;
    line-height: 1.5;
}

.view-event-details .detail-icon {
    font-size: 30px;
    margin-bottom: -15px;
}

.view-event-details h4.detail {
    color: var(--dark-highlight);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: -10px;
}

.view-event-details h2.detail {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.view-event-details .ticket-button {
    --background: var(--darker-highlight);
    width: 100% !important;
    --padding-top: 28px;
    --padding-bottom: 28px;
    font-size: 15px;
    margin-bottom: 20px;
}

.ticket-counter {
    margin-top: 20px;
}

.ticket-count {
    font-size: 20px;
    vertical-align: middle;
    line-height: 3.1;
}

.ticket-count-icon {
    margin-bottom: 5px;
    font-size: 17px;
    vertical-align: middle;
}

/* Checkout */

.inputs {
    margin-bottom: -10px;
}

.checkout-title {
    text-align: center;
    line-height: 2.2;
    margin-top: 0px;
    font-size: 20px;
}

.inputs h6 {
    font-size: 12px;
}

.inputs ion-input:focus {
    padding: 10px;
}

.inputs ion-input {
    --placeholder-opacity: .5;
    border-radius: 15px;
    padding: 10px !important;
    border: 2px solid lightgray;
}

.checkout-footer {
    z-index: 2;
    background-color: whitesmoke;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.fixed-wrapper {
    z-index: 1;
    padding-bottom: 150px;
}

.checkout-footer p {
    line-height: 1.5;
    font-size: 14px;
    text-align: center;
}

/* Order Confirmation */

.order-confirmation h3 {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin-top: -10px;
}

.ticket {
    border-radius: 16px;
    margin-bottom: 10px;
}

.ticket .ticket-image {
    height: 300px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: -50px;
    border-bottom-left-radius: -50px;
}

.ticket .ticket-float-left {
    float:left;
    width: 40px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 100%;
    margin-left: -25px;
    margin-top: -20px;
}
.ticket .ticket-float-right {
    float:right;
    width: 40px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 100%;
    margin-right: -25px;
    margin-top: -20px;
}

.ticket .ticket-details {
    padding-top: 30px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 325px;
    background-color: var(--darker-highlight);
}

.ticket .ticket-details h2 {
    font-weight: 900;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.ticket .ticket-details .verified {
    text-align: center;
    margin-top: 0px;
    font-weight: 400;
    font-size: 20px;
}

.ticket .ticket-details .view-event-description {
    color: white;
    font-size: 16px;
    line-height: 1.5;
}

.ticket .ticket-details .detail-icon {
    font-size: 30px;
    margin-bottom: -15px;
}

.ticket .ticket-details h4.detail {
    color: lightgray;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: -10px;
}

.ticket .ticket-details h3.detail {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.ticket .ticket-stats {
    text-align: center;
}

.barcode {
    overflow: hidden;
    max-height: 100px;
    margin-top: -20px;
    margin-left: 10px;
    margin-right: 10px;
}

.barcode svg {
    width: 100%;
}

.my-tickets {
    margin-bottom: -70px;
}

.my-tickets-wrapper {
    padding-bottom: 60px;
}

.my-tickets-wrapper .title {
    text-align: left !important;
}

.top-header-secondary {
   
}

.top-header-secondary h1 {
    text-align: left !important;
}

.blank-verified {
    margin-top: 30px !important;
}

.bg-transparent {
    background-color: transparent !important;;
}

ion-spinner {
    color: white;
}

.styled-border-left {
    border-left: 2px solid var(--primary);
    padding-left: 10px;
}
