.page-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    margin-top: 16px !important;
}

.cart-count-bar {
    margin-top: 30px;
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 40px;
}

.cart-count-title {
    color: black;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px;
    margin-bottom: 0px;
}

.cart-view-icon {
    margin-top: -4px;
    font-size: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.cart-item {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgray;
}

.cart-item-img {
    padding: 2px;
    width: 80%;
}

.cart-item-name {
    margin-top: 0px;
    font-size: 18px;
    font-weight: 500 !important;
}

.cart-item-category {
    margin-top: 0px;
    font-size: 13px;
    color: black;
}

.cart-item-price {
    margin-top: 5px !important;
}

.cart-item-qty {
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    font-size: 16px;
}

.cart-item-increment-button {
    margin-top: -8px;
    width: 35px;
    color: black;
    height: 35px;
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid black;
    padding-top: 1px;
    font-size: 16px;
}

ion-footer {
    background-color: white;
}

.cart-footer {
    background-color: whitesmoke !important;;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.cart-wrapperr {
    padding-bottom: 250px;
}

.footer-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.footer-subtotal {
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.footer-subtotal-price {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.footer-totals {
    margin-top:-10px;
}

.footer-final-pice {
    margin-top: 10px;
    border-top: 1px solid lightgray;
}

.cart-checkout-button {
    margin-top: 10px;
    width: 100%;
    --border-radius: 100px;
    --padding-top: 30px;
    font-size: 16px;
    --padding-bottom: 30px;
    --background: #443e94;
    --color: white;
    margin-bottom: 25px;
    opacity: 1;
}

.cart-checkout-button:hover {
    opacity: 1 !important;
}

.cart-item-options {
    list-style: none;
    padding: 0;
}

.cart-item-options li {
    color: #666;
}