.header-ios ion-toolbar:last-of-type {
    --border-width: 0px;
}

.view-menu-item {
    padding-bottom: 30px;
}

.view-menu-item h1 {
    text-align: center;
    margin-top: 30px;
    font-size: 28px;
    font-weight: 500;
    color: #1f293b;
}


.view-menu-item h2 {
    font-size: 18px;
    text-align: center;
    color: #1e283e;
    font-weight: 400;
}


.view-menu-item p {
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    color: gray;
    margin-bottom: 30px;
}

.view-menu-item h3 {
    color: #1e283e;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.view-menu-item img {
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
}

.view-menu-item-img {
    text-align: center;
}

.add-to-cart {
    background-color: transparent;
    border-radius: 100px;
    margin-top: 30px;
    padding-bottom: 20px;
    /* box-shadow: 0px 3px 10px 4px #e5e6eb; */
}

.add-to-cart {
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    bottom: 0;
    width: 100%; /* Optional: Make it stretch across the entire width */
    /* Add any other styles as needed */
  }

.add-to-cart h4 {
    font-size: 22px;
    color: #1e283e;
    margin-top: -5px;
    font-weight: 600;
}

.add-to-cart h5 {
    font-weight: 500;
    color: gray;
    font-size: 14px;
}

.add-to-cart button {
    margin-top: 10px;
    background-color: #443e94;
    color: white;
    font-weight: 600;
    border-radius: 100px;
    width: 100%;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 10px;
    transition: .1s;
}

.add-to-cart button:active {
    opacity: .85;
}


.back-button {
    --background: #f8f8f8;
    --color: gray;
    --padding-top:22px;
    --padding-bottom: 22px;
    --padding-start: 15px;
    --padding-end: 15px;
    --border-radius: 10px;
    --box-shadow: none;
    --background-activated: white !important;
}

.cart-button {
    --box-shadow: none;
    --background: transparent;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --padding-start: 0px;
    --padding-end: 0px;
    margin: unset;
    padding: unset;
    height: unset;
    --background-activated: transparent !important;
}

.cart-icon-wrapper {
    background-color: #f8f8f8;
    color: gray;
    padding-top:12px;
    padding-bottom: 12px;
    padding-left:13px;
    padding-right: 13px;
    border-radius: 10px;
}

.cart-icon {
    color: black;
    font-size: 18px;
}

.cart-count {
    width: 20px;
    height: 20px;
    background-color: #443e94;
    margin-left: -15px;
    margin-top: -25px;
    padding-top: 4px;
    border-radius: 100%;
    font-size: 12px;
}

.top-bar {
    padding-top: 10px;
}

.menu-item-img-wrapper {
    min-height: 50vh;
    background-size: cover !important;
}
.viewing-item-wrapper {
    padding-bottom: 120px;
}

.viewing-item-wrapper h1 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 28px;
}

.viewing-item-wrapper p {
    line-height: 1.5;
    color: gray;
    font-size: 16px;
}

.align-right {
    text-align: right;
}

.add-checkbox {
    margin-top: 20px;
}

.cart-option {
    padding-bottom: 10px;
    margin-top: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.cart-option:last-child{
    border-bottom: 1px solid transparent;
}

.cart-option h5 {
    color: #444;
}