.no-padding {
    margin: 0px !important;
    padding: 0px !important;
}

.background-white {
    background: red !important;
}

.wrapper {
    margin-left: 20px;
    margin-right: 20px;
}

.welcome-text {
    margin-top: 30px;
    font-size: 18px;
    color: black;
    line-height: 1;
    margin-bottom: 20px;
}

.welcome-intro {
    margin-bottom: 0px;
    font-size: 28px;
    color: black;
}

.search-menu {
    margin-top: 10px;
    --border-radius: 20px;
    padding: 0px;
    background-color: transparent;
    --box-shadow: 0px 3px 10px 4px #e5e6eb;
    --color: gray;
    margin-bottom: 10px;
    --background: white !important;
}

.test {
    display: inline-block;
    height: 50px;
}

.horizontal {
    overflow-x: scroll;
    white-space: nowrap;
}

.category {
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}

.category-img {
    margin: 0 auto;
    padding: 13px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
}

.category:first-child {
    margin-left: 20px;
}

.category-text {
    transition: .3s;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    border: 2px solid #f8f8f8;
    border-radius: 100px;
    padding: 8px 16px;
}

.active-category h5 {
    border: 2px solid black;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    border-radius: 100px;
    padding: 8px 16px;
}

.category-title {
    margin-top: 30px;
    font-size: 18px;
    color: black;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    --background: white !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    top: 14px;
}

  .menu-items {
      height: 410px;
  }

  .menu-item {
      position: relative;
      /*box-shadow: 0px 3px 10px 4px #e5e6eb;*/
      width: 250px;
      height: 350px;
      background-color: #f8f8f8;
      border-radius: 10px;
      background-size: cover !important;
      display: inline-block;
      margin-right: 20px;
  }

  .menu-img {
      padding: 30px;
      width: 100%;
      margin: 0 auto;
      padding-top: 20px;
  }

  .menu-text {
    padding: 20px;
  }

  .menu-text h4 {
      color: black;
      font-size: 13px;
      font-weight: 500;
  }

  .menu-text h5 {
      color: #656c7c;
      font-size: 12px;
      margin-top: -4px;
  }

  .menu-pricing {
      position: absolute;
      bottom: 0;
      width: 100%;
      white-space: normal;
      padding: 20px;
  }

  .dollar-sign {
      font-size: 12px;
      vertical-align: top;
  }

  .category-display .menu-item:first-of-type {
    margin-left: 20px;
    /*background-color: #black !important;*/
  }
  /*

  .category-display .menu-item:first-of-type .menu-pricing h5 {
    color: white !important;
  }

  .category-display .menu-item:first-of-type .menu-pricing .add-button  {
    --color: white !important;
    --background: #4c5564 !important;
  }
  
   .category-display .menu-item:first-of-type .menu-text h4 {
    color: white !important;
  }
  */

  .menu-pricing h5 {
    color: white;
    font-weight: 700;
    font-size: 28px;
}

  .menu-pricing .add-button {
      --border-radius: 20px;
      --padding-top: 8px;
      --padding-bottom: 8px;
      --padding-start: 20px;
      --padding-end: 20px;
      --box-shadow: none;
      height: unset !important;
      margin-top: 9px;
      --color: white;
      font-size: 15px !important;
      --background: black;
      text-transform: none;
  }

  .right-align {
      text-align: right;
  }

  .restaurant-logo {
      width: 50px;
  }
  
.center-align {
    text-align: center !important;
}

.padding-bottom {
    padding-bottom: 30px !important;
}

.search-bar {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -60px;
    background-color:white;
    padding: 10px;
    z-index: 999; /* Ensure the search bar appears above other content */
    transition: 0.3s ease; /* Add smooth transition when revealing the search bar */
}

  .search-bar-visible {
      margin-top: 0px;
    --padding-top: 50px; /* Adjust the padding to make space for the revealed search bar */
    transition: 0.3s; /* Add smooth transition when adjusting padding */
  }